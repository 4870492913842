import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"
import Form from "../components/Form"

const Footer = () => {
  return (
    <div className="section" id="contact">
      <div className="container">
        <div className="footer-container">
          <Fade bottom cascade>
            <h1>Contact</h1>
            <h2>{data.contactSubHeading}</h2>
          </Fade>
          <Form></Form>
          
          <div className="social-icons">
            {data.social.map(socialLink => (
              <a
                href={socialLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={socialLink.img} alt="icons"></img>
              </a>
            ))}
          </div>
          <span>
            2021{" "}
            <a
              href='https://link.maybevu.com/'>maybeVu.com</a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
