import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import form from "../styles/form.css"
import Fade from "react-reveal/Fade"

function Form() {
    const [state, handleSubmit] = useForm("mbjqvyaj");
  if (state.succeeded) {
      return <p>Thanks for your message! I would get back to you ASAP.</p>;
  }
  return (
    <div className="section">
        <div className="container">
                
                    <form onSubmit={handleSubmit}>
      
                    <label htmlFor="name">
                        You are:
                    </label>
                    <input 
                        
                        id="full-name"
                        type="text" 
                        placeholder="First and Last Name"
                    />
                    <label htmlFor="email">
                        Your Email Address
                    </label>
                    <input 
                        
                        id="email"
                        type="email" 
                        name="email"
                        placeholder="Your email"
                    />
                    <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                    />
                    <label htmlFor="message">
                        Your Message
                    </label>
                    <textarea
                        rows="5"
                        id="message"
                        name="message"
                        placeholder="Your feedback - I absolutely appreciate it"
                    />
                    <ValidationError 
                        prefix="Message" 
                        field="message"
                        errors={state.errors}
                    />
                    <button className="primary-btn" type="submit" disabled={state.submitting}>
                        Submit
                    </button>
                    </form>
        </div>
    </div>

  );
}

export default Form
